/* eslint-disable no-console,no-undef,camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import InputTrigger from "react-input-trigger";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../Image';
import _, { isNull, isUndefined } from 'lodash';
import { OverlayTrigger, Popover } from "react-bootstrap";

import { UserDropdownContainer, ProfilePicture, ListDropdown, UserContainer, InputTextArea, DropDownStyledImage,
  ShowTaggedUser, ShoutImage, Cross, ShoutoutContainer, IconContainer, ShoutoutDiv, IconContainerNew} from "./styles";

import { getUsersToShoutout, getCompanyExemplifiesValues } from '../../redux/actions';
import { imgPath, ImageUrl } from '../../utils/constants';
import MoreRecognition from "./MoreRecognition";
import { withTranslation } from 'react-i18next';
import { InputImage } from "../ChallengeDetailsV2/styles";
import { checkImage, getOrientation, resetOrientation } from "../../utils/methods";
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";
import { toast } from "react-toastify";
import SkeletonLoder from "../common/skeletonLoder";
// import { IconContainerNew, InputImageNew } from "../ChallengeDetailsV2/styles";
const iSiOS = !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);

class SocialFeedTextarea extends Component {
  constructor() {
    super();
    this.state = {
      top: null,
      left: null,
      showSuggestor: false,
      startPosition: null,
      text: null,
      currentSelection: 0,
      employee: [],
      thankYouShoutout: null,
      congratsShoutout: null,
      jobWellDoneShoutout: null,
      textareaValue: '',
      textLength: null,
      trigger: {},
      taggedUser: '',
      showModal: false,
      imgSrcV2: [],
      displayEmoji: false,
      description: '',
      position: {start:0, end:0},
      comment: '',
    };
    this.emojiref = React.createRef();

    this.backdropRef = React.createRef();

    this.toggleSuggestor = this.toggleSuggestor.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
    const { getCompanyExemplifiesValues } = this.props;
    this.fetchUsers();
    this.checkDevice();
    getCompanyExemplifiesValues();
    const textArea = document.getElementById('textArea');
    if (textArea) {
      textArea.style.setProperty('color', '#649bb3', 'placeholder');
    }
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
  }

  checkDevice = () => {
    let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if(isAndroid) {
      let triggerObj = {};
      triggerObj.keyCode = 229;
      this.setState({
        trigger: triggerObj
      });
    } else {
      let triggerObj = {};
      triggerObj.keyCode = 50;
      triggerObj.shiftKey = !iSiOS;
      this.setState({
        trigger: triggerObj
      })
    }
  };

  toggleSuggestor(metaInformation) {
    const { hookType, cursor } = metaInformation;
    if (hookType === "start") {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height,
        startPosition: iSiOS ? (this.textarea.value.length === 0 ? 1 : this.textarea.value.length) : cursor.selectionStart
      });
    }
    if (hookType === "cancel") {
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }

  storeFetchedEmployee = () => {
    this.setState({
      employee: this.props.employeeData
    });
  };

  fetchUsers = () => {
    const { userCompany, fetchUsersToShoutout } = this.props;
    let obj = {};
    obj['company_id'] = userCompany.id;
    obj['name'] = this.state.text;
    obj['limit'] = 6;
    if(!_.isNull(userCompany.id))
      fetchUsersToShoutout(obj, this.storeFetchedEmployee);
  };

  handleInput(metaInformation) {
    if(metaInformation.text.includes('@')){
      this.setState({
        text: '',
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      },() => this.fetchUsers());
    }
    else{
      this.setState({
        text: metaInformation.text,
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      },() => this.fetchUsers());
    }

  }

  handleKey(event) {
    const { which } = event;
    const { currentSelection, employee } = this.state;
    
    if(_.isNaN(currentSelection)) {
      event.preventDefault();
      this.setState({
        currentSelection: 0
      });
    }

    if (which === 40) {
      event.preventDefault();

      this.setState({
        currentSelection: (currentSelection + 1) % employee.length,
      });
    }

    if (which === 38 ) { 
      event.preventDefault();
      if(currentSelection - 1 < 0)
        this.setState({
          currentSelection: employee.length - 1,
        })
      else 
        this.setState({
          currentSelection: (currentSelection - 1) % employee.length,
        });
    }

    if (which === 13 && this.state.showSuggestor && employee[currentSelection]) {
      event.preventDefault();

      const { employee, currentSelection, textLength, startPosition } = this.state;
      const { inspirationQuote } = this.props;
      const taggedUser = employee[currentSelection].fullname.trim();
      const userId = employee[currentSelection].uid;
      const newText = `${inspirationQuote.slice(0,startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null,
        textareaValue: newText,
        currentSelection: 0,
        taggedUser: taggedUser
      }, () => {
        this.props.updateText(newText, userId, taggedUser, true)
      });

      this.endHandler();
    }
  }

  handleClick = () => {
    const { employee, currentSelection, textLength, startPosition } = this.state;
    const { inspirationQuote } = this.props;
    let userArray = [...this.props.users];
    const taggedUser = employee[currentSelection].fullname.trim();
    if(userArray.indexOf(taggedUser) === -1)
      userArray.push(taggedUser);
    const userId = employee[currentSelection].uid;
    const newText = `${inspirationQuote.slice(0,startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: null,
      startPosition: null,
      textareaValue: newText,
      currentSelection: 0,
      taggedUser: taggedUser
    }, () => {
      this.props.updateText(newText, userId, taggedUser, true);
    });

    this.endHandler();
  }

  handleMouseHover = (index) => {
    this.setState({
      currentSelection: index,
    });
  }

  onScroll = (event) => {
    const scrollTop = event.target.scrollTop;
    if(this.backdropRef && this.backdropRef.current) {
      this.backdropRef.current.scrollTop = scrollTop;
    }
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.props;
    this.props.updateShoutoutArray(userIdArray, text, image, id);
    id >= 1 && this.changeModal();
  };

  removeShoutout = () => {
    const { shoutoutText, onRemoveUser, onRemoveShoutout } = this.props;
    !shoutoutText ? onRemoveUser() : onRemoveShoutout(shoutoutText)
  };

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmoji: !prev.displayEmoji
    }))
  }

  hideEmojiPicker = () => {
    this.setState({
      displayEmoji: false
    })
  }

  handleEmojiClick = (event) => {
    if (!isNull(this.emojiref) && !isUndefined(this.emojiref) && !(this.emojiref && this.emojiref.current && this.emojiref.current.contains(event.target))) {
      // this.setState({displayEmoji: false});
    }
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  onEmojiSelect = (emoji, id, native, skin) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.addInspirationV2(native);
  }

  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  addInspirationV2 = (e) => {
    let newText = this.state.comment.slice(0, this.state.position.start) + e + this.state.comment.slice(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + e.length, end: this.state.position.end + e.length },
      comment: newText,
    });
    this.props.addInspiration(newText)
  };

  onChange = (e) => {
    let position = this.getPosition(e.target);
    e.target.value !== ' ' ?
      this.setState({
        comment: e.target.value,
        position: position
      }) : null;
    this.props.addInspiration(e.target.value)
  };

  onChangeImageV2 = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let readers = new FileReader();
        readers.readAsDataURL(file);
        readers.onloadend = () => {
          this.setState({
            imgSrcV2: [readers.result],
            refreshFileReader: true
          });
          // this.props.updateImage([readers.result])
          getOrientation(file, (or) => {
            resetOrientation([readers.result], or, (baseImage) => {
              this.setState({
                imgSrcV2: [baseImage],
                refreshFileReader: false
              });
              // this.props.updateImage([baseImage])
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhotos').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('uploadPhotos').value = '';
    }
  };


  removeImage = () => {
    this.setState({
      imgSrcV2: []
    });
  };

  popover = (value) => (
    <Popover
      id="popover-basic"
      style={{
        width: "auto",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #005c87",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    > 
      <div
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#005c87",
          textAlign: "center",
        }}
      >
        {value}
      </div>
    </Popover>)

  render() {        
    const { top, left, showSuggestor, employee, currentSelection, trigger, showModal, imgSrcV2, comment } = this.state;
    const { userCompany, userIdArray, /*inspirationQuote*/ placeholder, isCommentOrReply, shoutoutText, 
      shoutoutImage, taggedUser, enableTrigger, exemplifiesValues, t } = this.props;

    if(!_.isNull(userCompany.id) && !this.props.employeeData) {
      return <div>
        <SkeletonLoder width={"477px"} height={"60px"} style={{borderRadius:'6px'}}/>
      </div>
    }
    return (
      <div>
        <ShoutoutDiv>
          { this.props.showGivenShoutout && 
            <div>
              { _.isNull(shoutoutText) || _.isNull(shoutoutImage) ?
                <ShowTaggedUser>
                  {`${taggedUser ? taggedUser : ""}`}
                  <Cross src={`${imgPath}/wrong-tick.png`} onClick={this.removeShoutout} />
                </ShowTaggedUser> :
                <ShowTaggedUser>
                  {`${taggedUser ? taggedUser : ""} - ${shoutoutText}`}
                  <ShoutImage src={`${ImageUrl}/${shoutoutImage}`} />
                  <Cross src={`${imgPath}/wrong-tick.png`} onClick={this.removeShoutout} />
                </ShowTaggedUser>
              }
            </div>
          }
        </ShoutoutDiv>

        <InputTextArea
          onKeyDown={this.handleKey}
          isCommentOrReply={isCommentOrReply}
          style={{height:"0px"}}
        >
          {imgSrcV2 && imgSrcV2.length >= 1 &&
            <InputImage className="InputImageSection">
              {imgSrcV2 && imgSrcV2.length >= 1 ? <img src={imgSrcV2} alt="uploadable" /> : ''}
              {imgSrcV2 && imgSrcV2.length >= 1 ? <button style={{position: 'absolute'}} onClick={() => this.removeImage()}><span style={{color: 'black'}} className="InputImageSpan">X</span></button> : ''}
            </InputImage>}
          <InputTrigger
            trigger={trigger}
            onStart={(metaData) => { enableTrigger && this.toggleSuggestor(metaData);}}
            onCancel={(metaData) => {enableTrigger && this.toggleSuggestor(metaData);}}
            onType={(metaData) => { enableTrigger && this.handleInput(metaData);}}
            endTrigger={(endHandler) => {this.endHandler = endHandler;}}
          >
            <textarea
              id="textArea"
              placeholder={t(placeholder)}
              rows={comment.length >= "120" ? "4" : "5"}
              name={t("description")}
              onChange={(e) => this.onChange(e)}
              value={comment}
              data-gramm_editor="false"
              className="CustomTextAreaField"
              onScroll={this.onScroll}
              spellCheck="false"
              ref={(node) => this.textarea = node}
              showEmojiPicker={this.showEmojiPicker}
              onClick={(e) => this.onChange(e)}
              style={{display:'flex',alignItems:"center",float:"inherit",padding:"14px 15px",background:"#faf9f4",border:"1px solid #afcbd3",color:"#005c87", borderRadius:"20px",paddingTop:comment.length == "" ? "20px" : "10px",paddingBottom:comment.length == "" ? '5px': '10px',placeholderColor:"#649bb3"}}
            />          
          </InputTrigger>
          {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}

          <IconContainerNew cameraIcon style={{right:'60px',top:"6px"}}>
            <OverlayTrigger placement="bottom" overlay={this.popover("Emoji")}>
              <img style={{marginRight: '0px', cursor: 'pointer',width:"46px", height:"46px"}} onClick={this.showEmojiPicker} src={ImageUrl+"/images/new_emoji_icon.png"}></img>
            </OverlayTrigger>
            {/* <InputImageNew htmlFor="uploadPhotos" noMarginTop>
              <img src="/images/camera_icon.svg" />
              <input
                id="uploadPhotos"
                type="file"
                name="image"
                accept=".jpeg, .png, .jpg*"
                multiple={false}
                onChange={(e) => this.onChangeImageV2(e)}
                onClick={(e) => e.target.files[0] && this.onChangeImageV2(e)}
              />
            </InputImageNew> */}
          </IconContainerNew>
          <UserContainer
            showSuggestor={ showSuggestor }
            top={ `${top}px` }
            left={ `${left}px` }
          >
            {employee !== null ? employee.map((user, index) => (
              <UserDropdownContainer
                key={index}
                index={index}
                onMouseEnter={() => this.handleMouseHover(index)}
                currentSelection={currentSelection}
                onClick={this.handleClick}
              >
                <ProfilePicture>
                  <DropDownStyledImage width="100%" left height='30px'>
                    <div className="middle">
                      <Image image={user.profile_image} alt={user.fname} /> 
                    </div>
                    <ListDropdown
                      index={index}
                      currentSelection={currentSelection}
                    >
                      { t(user.fullname) }
                    </ListDropdown>
                  </DropDownStyledImage>
                </ProfilePicture>
              </UserDropdownContainer>
            )) : null}
          </UserContainer>
        </InputTextArea>
        <div>
          <IconContainer>
            { userIdArray.length !== 0 ? 
              <div>
                {exemplifiesValues ? exemplifiesValues.slice(0,3).map((recognition) => (
                  <ShoutoutContainer 
                    value={t(recognition.core_value)}
                    onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                    key={recognition.id}
                    width={recognition.core_value === 'Job Well Done' ? '155px' : '135px'}
                  >
                    <img src={`${ImageUrl}/${recognition.image}`} />
                    {t(recognition.core_value)}
                  </ShoutoutContainer>
                )) : null}
                {exemplifiesValues &&  exemplifiesValues.length > 3 ?
                  <ShoutoutContainer width="155px" display={1} height ='30px'
                    value={t('MoreRecognition')}
                    onClick={() => this.changeModal()}
                  >
                    {t("More Recognition")}
                  </ShoutoutContainer>
                  : null}
              </div> : null
            }
          </IconContainer>
        </div>
        {showModal ?
          <MoreRecognition showModal={showModal} onClose={this.changeModal} collectSelectedRecognition={this.onShoutoutSelect} exemplifiesValues={exemplifiesValues}/>
          : null
        }
      </div>
    );
  }
}

SocialFeedTextarea.propTypes = {
  employeeData: PropTypes.array,
  fetchUsersToShoutout: PropTypes.func,
  addInspiration: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  inspirationQuote: PropTypes.string,
  updateText: PropTypes.func,
  users: PropTypes.array,
  userIdArray: PropTypes.array,
  placeholder: PropTypes.string,
  updateShoutoutArray: PropTypes.func,
  showGivenShoutout: PropTypes.bool,
  onRemoveShoutout: PropTypes.func,
  isCommentOrReply: PropTypes.string,
  shoutoutImage: PropTypes.string,
  shoutoutText: PropTypes.string,
  taggedUser: PropTypes.string,
  onRemoveUser: PropTypes.func,
  enableTrigger: PropTypes.bool,
  exemplifiesValues: PropTypes.array,
  getCompanyExemplifiesValues: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  employeeData: state.peopleHome.employeeData,
  exemplifiesValues: state.social.exemplifiesValues
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SocialFeedTextarea));
